import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './root.reducers';
import config from 'appConfig';

const composeEnhancers = process.env.NODE_ENV !== 'production' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

const configureStore = preloadedState => {
  const sagaMiddleware = createSagaMiddleware();
  const logRocketMiddleware = config?.LogRocket?.reduxMiddleware();

  const middlewares = [sagaMiddleware];
  if(!config.isApp) {
    middlewares.push(logRocketMiddleware);
  }

  return {
    ...createStore(
      rootReducer,
      preloadedState,
      composeEnhancers(applyMiddleware(...middlewares))
    ),
    runSaga: sagaMiddleware.run
  };
};

export default configureStore;
