const prefix = 'MODAL/';
export const SET_NON_SERVICEABLE_MODAL = `${prefix}SET_NON_SERVICEABLE_MODAL`;
export const SET_MERGE_CART_ON_LOGIN_MODAL = `${prefix}SET_MERGE_CART_ON_LOGIN_MODAL`;
export const SET_ORDER_FAILED_MODAL = `${prefix}SET_ORDER_FAILED_MODAL`;
export const SET_PREORDER_MODAL = `${prefix}SET_PREORDER_MODAL`;
export const SET_LOGIN_MODAL = `${prefix}SET_LOGIN_MODAL`;
export const SET_ADDRESS_MODAL = `${prefix}SET_ADDRESS_MODAL`;
export const SET_LOCATION_SEARCH_MODAL = `${prefix}SET_LOCATION_SEARCH_MODAL`;
export const SET_LOCATION_MAP = `${prefix}SET_LOCATION_MAP`;
export const SET_ADDRESSLIST_MODAL = `${prefix}SET_ADDRESSLIST_MODAL`;
export const SET_SUBSCRIPTION_SUMMARY_MODAL = `${prefix}SET_SUBSCRIPTION_SUMMARY_MODAL`;
export const SET_PAYMENT_PENDING_MODAL = `${prefix}SET_PAYMENT_PENDING_MODAL`;
export const SET_RECHARGE_FOR_ORDER_MODAL = `${prefix}SET_RECHARGE_FOR_ORDER_MODAL`;
export const SET_CONFIRMATION_PAGE_MODAL = `${prefix}SET_CONFIRMATION_PAGE_MODAL`;
export const SET_PAYMENT_FAIL_MODAL = `${prefix}SET_PAYMENT_FAIL_MODAL`;
export const SET_PAYMENT_SUMMARY_MODAL = `${prefix}SET_PAYMENT_SUMMARY_MODAL`;
export const SET_EARNING_HISTORY_MODAL = `${prefix}SET_EARNING_HISTORY_MODAL`;
export const SET_SCRATCH_CARD_MODAL = `${prefix}SET_SCRATCH_CARD_MODAL`;

export const showNonServiceableModal = data => ({
  type: SET_NON_SERVICEABLE_MODAL,
  data,
});

export const showMergeCartOnLoginModalModal = data => ({
  type: SET_MERGE_CART_ON_LOGIN_MODAL,
  data,
});

export const showPaymentPendingModal = data => ({
  type: SET_PAYMENT_PENDING_MODAL,
  data,
});

export const showConfirmationPageModal = data => ({
  type: SET_CONFIRMATION_PAGE_MODAL,
  data,
});

export const showOrderFailedModal = data => ({
  type: SET_ORDER_FAILED_MODAL,
  data,
});

export const setPreorderModal = data => ({type: SET_PREORDER_MODAL, data});

export const showLoginModal = data => ({
  type: SET_LOGIN_MODAL,
  data,
});

export const showAddressModal = data => ({
  type: SET_ADDRESS_MODAL,
  data,
});

export const showLocationSearchModal = data => ({
  type: SET_LOCATION_SEARCH_MODAL,
  data,
});

export const showLocationMap = data => ({
  type: SET_LOCATION_MAP,
  data,
});

export const showAddressListModal = data => ({
  type: SET_ADDRESSLIST_MODAL,
  data,
});

export const setSubscriptionSummaryModal = data => ({
  type: SET_SUBSCRIPTION_SUMMARY_MODAL,
  data,
});

export const setRechargeForOrderModal = data => ({
  type: SET_RECHARGE_FOR_ORDER_MODAL,
  data,
});

export const setPaymentFailModal = data => ({
  type: SET_PAYMENT_FAIL_MODAL,
  data,
})

export const setPaymentSummaryModal = data => ({
  type: SET_PAYMENT_SUMMARY_MODAL,
  data,
})

export const showEarningHistoryModal = data => ({
  type: SET_EARNING_HISTORY_MODAL,
  data,
});

export const showScratchCardModal = data => ({
  type: SET_SCRATCH_CARD_MODAL,
  data,
});
