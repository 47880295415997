import { all, call, put } from 'redux-saga/effects';
import {
  SET_PDP_DETAILS,
  SET_LOADING_PDP,
  SET_CATEGORY_PLP_DETAILS,
  SET_DEALS,
  SET_CATEGORIES,
  SET_SEARCH_LIST,
  SET_SECTION_PLP,
  SET_RANDOM_SECTION,
} from './product.action';
import {
  getCategoriesApi,
  getCategoryPLPDetailsApi,
  getComboPDPDetailsApi,
  getDealsApi,
  getPDPDetailsApi,
  getSearchProductsApi,
  notifyUserApi,
  getBestsellersApi,
  getRecentlyPurchasedApi,
  getRecentlyViewedApi,
  getYouMayLikeApi,
} from './product.api';
import notification from 'hb-redux-store/util/notificationService';
import { SET_NOTIFICATION_MSG } from '../../initial.action';
import { showCartSaga } from '../cart/cart.saga';
import config from '../../../appConfig';
import { chooseRandomItem, getDiscount } from '../../util/miscellaneousServices';
import request from '../../util/request';
import { getHomePageSectionApi } from '../home/home.api';
import { HOMEPAGE_SECTIONS } from '../../util/constantServices';

const { trackGtmEvent = () => {}, clevertap = null } = config;

export function* getPDPDetailSaga({ data }) {
  try {
    yield put({ type: SET_LOADING_PDP, data: true });
    const productResp = yield call(getPDPDetailsApi, data);
    yield put({
      type: SET_PDP_DETAILS,
      data: {
        pdpDetails: (productResp && productResp.data) || null,
      },
    });
    if (!config.isApp) {
      const [sku] = productResp?.data?.skus || [{}];
      const gtmData = {
        eventName: 'viewItem',
        eventData: {
          category: 'products > view_product',
          value: sku.retailPrice,
          label: `${sku.sku}: ${productResp?.data?.productName}`,
        }
      };
      trackGtmEvent(gtmData);

      // Clevertap Analytics
      const key = 'View Product';
      delete sku.skuAttributes;
      const payload = {
        ...sku,
        productName: productResp?.data?.productName,
        productId: data,
      };
      if(clevertap) clevertap.sendCleverTapEvent(key, payload);
    }
  } catch (e) {
    console.log('Err@ getPDPDetailSaga: ', e, data);
    if(!config.isApp) {
      notification.error({
        message: 'Error fetching product details. Please try again.',
      });
    }

    yield put({ type: SET_LOADING_PDP, data: false });
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message:
          'Product Details not found'
      }
    });
    // setTimeout(() => {
    //   history.push('/')
    // }, 300);
  }
}

export function* getComboPDPDetailSaga({ data }) {
  try {
    yield put({ type: SET_LOADING_PDP, data: true });
    const productResp = yield call(getComboPDPDetailsApi, data);
    const pdpDetails = {
      ...productResp.data,
    };
    pdpDetails.productName = pdpDetails.comboName;
    pdpDetails.description = pdpDetails.comboDescription;
    const discount = pdpDetails.comboDiscountPercentage || getDiscount(pdpDetails);
    const dummySku = {
      availableQuantity: pdpDetails.availableQuantity,
      inCartQuantity: pdpDetails.inCartQuantity,
      isInventoryAvailable: pdpDetails.isInventoryAvailable,
      maxQuantityCart: pdpDetails.maxQuantityCart,
      retailPrice: pdpDetails.retailPrice,
      salePrice: pdpDetails.salePrice,
      isPresale: false,
      onSale: true,
      saleDiscountPercentage: `${discount}% OFF`,
    };
    pdpDetails.skus = [dummySku];
    pdpDetails.images = [
      { id: 'prim-x', url: pdpDetails.comboPrimaryImage, isPrimary: true, },
      ...pdpDetails.comboSecondaryImages.map(img => ({
        id: img.comboProductImageId,
        url: img.imageUrl,
        isPrimary: false,
      }))
    ];
    if (!config.isApp) {
      const gtmData = {
        eventName: 'viewItem',
        eventData: {
          category: 'products > view_combo_product',
          value: dummySku.retailPrice,
          label: `${pdpDetails.comboProductId}: ${pdpDetails.comboName}`
        }
      };
      trackGtmEvent(gtmData);
    }
    yield put({ type: SET_PDP_DETAILS, data: { pdpDetails } });
  } catch (e) {
    console.log('Err@ getComboPDPDetailSaga: ', e, data);
    if (!config.isApp) {
      notification.error({
        message: 'Error fetching combo product details. Please try again.',
      });
    }

    yield put({ type: SET_LOADING_PDP, data: false });
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: 'Combo Product Details not found',
      }
    });
  }
}

export function* getCategoryPLPDetailSaga({ data }) {
  try {
    yield put({ type: SET_LOADING_PDP, data: true });
    const { data: resp } = yield call(getCategoryPLPDetailsApi, data);
    yield put({ type: SET_CATEGORY_PLP_DETAILS, data: resp });
    if (!config.isApp) {
      const gtmData = {
        eventName: 'viewItemList',
        eventData: {
          category: `products > view_plp > ${resp.categoryName.toLowerCase()}`,
          value: data.categoryId,
          label: `${data.categoryId}: ${resp.categoryName} (isProvisional: ${data.isProvisional})`,
        }
      };
      trackGtmEvent(gtmData);
    }
  } catch (e) {
    console.log('Err@ getCategoryPLPDetailSaga: ', e);
    yield put({ type: SET_LOADING_PDP, data: false });
  }
}

export function* getSectionPLPSaga({ data }) {
  try {
    yield put({ type: SET_LOADING_PDP, data: true });
    const sectionPromise = [call(() => request.get(data.apiEndpoint))];
    if (data.isDesktop) {
      sectionPromise.push(call(getHomePageSectionApi));
    } else {
      sectionPromise.push(call(() => Promise.resolve({ data: true })));
    }
    const [{ data: resp }, { data: homepageResp }] = yield all(sectionPromise);
    const sectionData = {
      ...resp,
      categoryName: data.title,
      subTitle: data.subTitle,
      banners: null,
    };
    if (data.isDesktop) {
      const banners = homepageResp.sections.find(
        s => s.sectionType === HOMEPAGE_SECTIONS.BANNER_CUSTOM_TILES
      );
      if (banners?.sectionData) {
        sectionData.banners = banners.sectionData.map(ct => {
          const {
            imageUrl,
            actionUrl,
            actionType,
            actionValue
          } = ct.tileRow[0].tileColumn[0];
          const banner = {
            type: HOMEPAGE_SECTIONS.BANNER_CUSTOM_TILES,
            customTileId: ct.customTileId,
            bannerImage: imageUrl,
            actionType,
            actionValue,
            actionUrl,
            ct,
          };
          return banner;
        });
      }
    }
    yield put({
      type: SET_SECTION_PLP,
      data: sectionData,
    });
  } catch (e) {
    console.log('Err@ getSectionPLPSaga: ', e);
    yield put({ type: SET_LOADING_PDP, data: false });
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: 'Fail to get products.'
    });
  }
}

export function* getDealsSaga() {
  try {
    const { data } = yield call(getDealsApi);
    yield put({ type: SET_DEALS, data });
  } catch (e) {
    console.log('Err@ getDealsSaga: ', e);
  }
}

export function* getRandomSectionSaga({ data = {} }) {
  try {
    const SECTIONS = {
      BESTSELLERS: {
        apiUrl: getBestsellersApi,
        title: 'Bestsellers',
      },
      DEALS: {
        apiUrl: getDealsApi,
        title: 'Deals Of The Day',
      },
      RECENTLY_VIEWED: {
        apiUrl: getRecentlyViewedApi,
        title: 'Recently Viewed',
      },
      RECENTLY_PURCHASED: {
        apiUrl: getRecentlyPurchasedApi,
        title: 'Recently Purchased',
      },
      YOU_MAY_LIKE: {
        apiUrl: getYouMayLikeApi,
        title: 'You May Also Like',
      }
    };
    const { sectionSource, productId = null } = data;
    let section = SECTIONS.DEALS;
    if (sectionSource === 'CART') {
      const sections = [SECTIONS.DEALS, SECTIONS.BESTSELLERS, SECTIONS.YOU_MAY_LIKE];
      section = chooseRandomItem(sections);
    } else if (sectionSource === 'PDP') {
      const sections = [SECTIONS.RECENTLY_PURCHASED, SECTIONS.RECENTLY_VIEWED, SECTIONS.YOU_MAY_LIKE];
      section = chooseRandomItem(sections);
    }
    console.log('RandomSection on: ', sectionSource, section);
    let { data: respData } = yield call(section.apiUrl);
    if (!respData.products.length) {
      section = SECTIONS.DEALS;
      respData = (yield call(section.apiUrl)).data;
    }
    let products = respData.products;
    if (sectionSource === 'PDP' && productId) {
      products = respData.products.filter(p => p.productId != productId);
    }
    yield put({
      type: SET_RANDOM_SECTION,
      data: { products, title: section.title, sectionSource }
    });
  } catch (e) {
    console.log('Err@ getRandomSectionSaga: ', e);
  }
}

export function* getCategoriesSaga() {
  try {
    yield put({ type: SET_LOADING_PDP, data: true });
    const { data } = yield call(getCategoriesApi);
    yield put({ type: SET_CATEGORIES, data });
  } catch (e) {
    console.log('Err@ getCategoriesSaga: ', e);
    yield put({ type: SET_LOADING_PDP, data: false });
  }
}

export function* notifyUserSaga({ data, cb }) {
  try {
    const { data: resp } = yield call(notifyUserApi, data.sku);
    if (data.isCart) yield call(showCartSaga);
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message:
          resp?.description ||
          'Success! You will be notified when this product is in stock'
      }
    });
    if(cb) {
      cb()
    }
  } catch (e) {
    console.log('Err@ notifyUserSaga: ', e);
    const message =
      e.data?.description ||
      e.data?.error?.errorMessage ||
      'Unable to process your request';
    if (data?.errorCb) {
      data.errorCb(e);
    } else {
      yield put({
        type: SET_NOTIFICATION_MSG,
        data: { show: true, message }
      });
    }
  }
}

export function* getSearchProductsSaga({ data, cb }) {
  try {
    yield put({ type: SET_LOADING_PDP, data: true });
    const suggestions = [];
    if (data?.length) {
      const { data: resp } = yield call(getSearchProductsApi, data);
      resp.map(p => {
        if (p) {
          const product = {
            productId: p.productId,
            productName: p.productName,
            deliveryDays: p.deliveryDays,
            baseCurrency: p.baseCurrency,
            skus: p.skus.map(s => ({
              sku: s.sku,
              onSale: s.onSale,
              retailPrice: s.retailPrice,
              salePrice: s.salePrice,
              isPresale: s.isPresale,
              cuts: s.cuts,
              quantity: s.quantity,
              maxQuantityCart: s.maxQuantityCart,
              isInventoryAvailable: s.isInventoryAvailable,
              inCartQuantity: s.inCartQuantity,
              skuAttributes: [],
            })),
          };
          p.images.map(img => {
            if (img.isPrimary) {
              product.productImage = img.url;
            }
          });
          suggestions.push(product);
        }
      });
    }
    yield put({ type: SET_SEARCH_LIST, data: suggestions });
    cb && cb(suggestions);
  } catch (e) {
    console.log('Err@ getCategoriesSaga: ', e);
    yield put({ type: SET_LOADING_PDP, data: false });
  }
}
